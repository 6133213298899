.maintenance-mode-container {
  width: 100%;
  height: 100%;
  padding: 0.7142857143em;
  background: linear-gradient(var(--theme-header-gradient-left), var(--theme-header-gradient-right) 57.1428571429em), var(--theme-image-tiling-bg) repeat;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.maintenance-mode-container .logo {
  background: center center/contain no-repeat var(--theme-image-logo);
  width: 70%;
  height: 7.1428571429em;
  margin-bottom: 5.7142857143em;
}
.maintenance-mode-container .title {
  font-size: 2em;
  margin-bottom: 0.7142857143em;
}
.maintenance-mode-container .text {
  font-size: 1.3em;
  line-height: 2em;
}
.maintenance-mode-container .access-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.maintenance-mode-container .access-container .toggle-button {
  display: block;
  background: transparent;
  color: #ffffff !important;
}
.maintenance-mode-container .access-container .access-form {
  color: #000000;
  background: #ffffff;
}

.mobile-device .maintenance-mode-container {
  min-height: 100vh;
}
